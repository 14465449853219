
.embedded-markdown {
    word-wrap: break-word;
    overflow-x: auto;
    overflow-y: hidden;
}

.embedded-markdown a {
    text-decoration: none;
    color: #304ffe;
}

.embedded-markdown a:hover {
    text-decoration: underline;
}

.embedded-markdown img {
    max-width: 100%;
}

.embedded-markdown hr {
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0.12);
    margin: 25px 0;
}

.embedded-markdown table {
    width: 100%;
    border-collapse: collapse;
}

.embedded-markdown th {
    padding: 5px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    text-align: left;
}

.embedded-markdown td {
    padding: 5px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.embedded-markdown code {
    border-radius: 3px;
    background: rgba(167, 209, 238, 0.2);
    font-family: "Courier New", monospace, serif;
    font-size: 16px;
}

.embedded-markdown pre {
    max-width: 100%;
    overflow: auto;
}

.embedded-markdown pre {
    border-radius: 6px;
    max-width: 100%;
    padding: 20px;
    overflow: auto;
    color: #000;
    background: rgba(192, 220, 243, 0.15);
    border: 1px solid rgba(192, 220, 243, 0.2);
    margin: 15px 0;
}

.embedded-markdown pre code {
    border-radius: 0;
    background: none;
}

.embedded-markdown blockquote {
    border-radius: 6px;
    background: rgba(192, 220, 243, 0.15);
    border: 1px solid rgba(192, 220, 243, 0.2);
    margin: 15px 0;
    padding: 15px;
}

.embedded-markdown p {
    margin: 15px 0;
}

.embedded-markdown *:first-child {
    margin-top: 0;
}

.embedded-markdown *:last-child {
    margin-bottom: 0;
}