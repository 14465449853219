
html {
    overflow-y: scroll;
}

.MuiContainer-root {
    padding-top: 0 !important;
}

.scroll-margin-top-navbar {
    scroll-margin-top: 70px;
}