


.app-header, .app-header__fixed {
    min-width: 320px;
    height: 57px;
    overflow: hidden;
}

.app-header {
    margin-bottom: 24px;
}

.app-header__fixed {
    z-index: 99;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
}

.app-header__content {
    padding: 12px 25px;
    display: flex;
}

.app-header__content .MuiButton-text {
    color: #111;
}

.app-header-logo__link {
    text-decoration: none;
    color: #111;
    font-size: 22px;
    line-height: 32px;
}

.app-header-logo__icon {
    vertical-align: middle;
    display: inline-block;
    margin-right: 10px;
}

.app-header-logo__text {
    vertical-align: middle;
    display: inline-block;
    font-weight: 300;
}

.app-header-menu {
    flex-grow: 1;
}

.app-header-menu__ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.app-header-menu__li {
    list-style: none;
    margin-right: 40px;
    line-height: 32px;
}

.app-header-menu__li:last-child {
    margin-right: 0;
}

.app-header-menu_link {
    color: #111;
    text-decoration: none;
    font-size: 18px;
    display: block;
}

.app-header-menu_link--active {
    color: #111;
}

@media screen and (max-width: 549px) {
    .app-header-logo__text {
        display: none;
    }
}

@media screen and (max-width: 449px) {
    .app-header-menu__li {
        margin-right: 25px;
    }
}