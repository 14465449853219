
.bg-bubbles-wrapper {
    background: #50a3a2;
    background: linear-gradient(to bottom right, #304ffe 0%, #404a9d 100%);
    width: 100%;
    height: 100%;
    position: relative;
}

.bg-bubbles-overlay {
    position: absolute;
    inset: 0;
    z-index: 2;
    background-size: 40vw;
    background-repeat: no-repeat;
    background-position: center center;
}

@media screen and (max-width: 899px) {
    .bg-bubbles-overlay {
        display: none;
    }
}

.bg-bubbles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    z-index: 1;
    overflow: hidden;
    color: rgba(white, 0.5);

    li {
        position: absolute;
        list-style: none;
        display: block;
        width: 40px;
        height: 40px;
        background-color: rgba(white, 0.15);
        bottom: -160px;
        animation: square 25s infinite;
        transition-timing-function: linear;
        border-radius: 6px;
        padding: 10px;
        box-sizing: border-box;

        &:nth-child(1) {
            left: 10%;
        }

        &:nth-child(2) {
            left: 20%;

            width: 80px;
            height: 80px;

            animation-delay: 1s;
            animation-duration: 17s;
        }

        &:nth-child(3) {
            left: 25%;
            animation-delay: 2s;
        }

        &:nth-child(4) {
            left: 40%;
            width: 60px;
            height: 60px;

            animation-duration: 22s;

            background-color: rgba(white, 0.25);
        }

        &:nth-child(5) {
            left: 70%;
        }

        &:nth-child(6) {
            left: 80%;
            width: 120px;
            height: 120px;

            animation-delay: 1.5s;
            background-color: rgba(white, 0.2);
        }

        &:nth-child(7) {
            left: 32%;
            width: 160px;
            height: 160px;

            animation-delay: 3.5s;
        }

        &:nth-child(8) {
            left: 55%;
            width: 70px;
            height: 70px;

            animation-delay: 7.5s;
            animation-duration: 40s;
        }

        &:nth-child(9) {
            left: 25%;
            width: 80px;
            height: 80px;

            animation-delay: 1s;
            animation-duration: 40s;
            background-color: rgba(white, 0.1);
        }

        &:nth-child(10) {
            left: 90%;
            width: 160px;
            height: 160px;

            animation-delay: 5.5s;
        }
    }
}

@keyframes square {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-110vh) rotate(600deg);
    }
}